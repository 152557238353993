<template>
  <div class="map">
    <div class="map-content">
      <div class="container is-max-widescreen">
        <p class="map-title">Partner Tambak Kami</p>
        <div class="columns is-tablet">
          <div class="column is-4-tablet map-item map-item-1">
            <p class="map-item-count">60+</p>
            <p>{{ $t('map.farm') }}</p>
          </div>
          <div class="column is-4-tablet map-item map-item-2">
            <p class="map-item-count">500+</p>
            <p>{{ $t('map.hectares') }}</p>
          </div>
          <div class="column is-4-tablet map-item map-item-3">
            <p class="map-item-count">{{ $t('map.coverage') }}</p>
            <p>{{ $t('map.coverage_detail') }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="is-relative is-flex">
      <img src="/assets/img/map/map-2.jpg" alt="">
    </div>
  </div>
</template>

<script>

export default {
  name: "SgaraMap2",
  components: {},
  data: () => {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.map {
  margin-top: -10px;
  position: relative;
  z-index: 5;

  .map-title {
    font-size: 64px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 1rem;
    @media (max-width: 768px) {
      font-size: 36px;
    }
  }

  .map-content {
    background-color: #37465B;
    color: #FFFFFF;
    padding-top: 2rem;
    padding-bottom: 0;
  }

  .map-item {
    font-size: 32px;
    @media (max-width: 768px) {
      text-align: center;
      font-size: 24px;
    }

    .map-item-count {
      font-size: 64px;
      font-weight: bold;
      @media (max-width: 768px) {
        font-size: 64px;
      }
    }
  }
}
</style>
